import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { AGENT_ACTION, getDocExtension, LEAD_SUB_STATUS, REVIEW_PAGES, STATUS_CODES } from '../../../config/constants';
import { decode } from '../../../config/crypto';
import { GET_CUSTOMER_LEAD_DETAIL } from '../../../services/customer.ggl';
import { LEADS_HISTORY_QUERY, STATUS_PAYMENT_DATA } from "../../../services/leads.gql";
import logo_image from '../../../webroot/images/carmudi-logo.png';
import FormsyInputField from '../../elements/FormsyInputField';
import Modal from '../../elements/Modal';
import { STATUS_TRACKING } from '../formFields/statusTrackingFormFields.json';
import PreviewRating from "../PreviewRating";
import ReviewRating from '../Rating';
import LeadStatusTrackHistory from "./LeadStatusTrackHistory";

const StatusTracking = (props) => {
    const [editServicesPrice, setEditServicesPrice] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [showSuccessPage] = useState('');
    const [isCustomerValidated, setIsCustomerValidated] = useState(false);
    const [validateCustomerInput, setValidateCustomerInput] = useState({});
    const [titleTransferDetails, setTitleTransferDetails] = useState({});
    const [leadDetails, setLeadDetails] = useState({});
    const [leadDocs, setLeadDocs] = useState([]);
    const [leadDocsName, setLeadDocsName] = useState([]);
    const [selectedDocId, setSelectedDocId] = useState();
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [paymentStatusDetails, setPaymentStatusDetails] = useState([]);
    const [docIndex, setDocIndex] = useState(0);
    const client = useApolloClient();
    let { lead_id, agent_id } = useParams();
    const { t } = useTranslation('language');

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const getLeadHistory = (lead_id) => {
        if (!lead_id) return;
        executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client)
            .then((result) => {
                if (result?.data?.get_lead_history) {
                    let lead_history = result?.data?.get_lead_history;
                    setHistoryList(lead_history);
                } else {
                }
            })
            .catch((error) => {
                console.error('Query error:', error);
            });
    };
    const getPaymentStatusDetails = (lead_id) => {
        if (!lead_id) return;
        executeGraphQLQuery(STATUS_PAYMENT_DATA(lead_id, 1), client)
            .then((resp) => {
                if (resp?.data?.get_status_payment_details)
                    setPaymentStatusDetails(resp?.data?.get_status_payment_details);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        setValidateCustomerInput((prev) => ({
            ...prev,
            lead_id: +decode(lead_id),
            agent_id: +decode(agent_id),
        }));
    }, [lead_id, agent_id]);

    useEffect(() => {
        const { leadDocDropDown } = leadDocs.reduce(
            (result, doc) => {
                if (!result?.alreadyPushed.includes(doc?.doc_detail?.id)) {
                    const label = (doc?.doc_detail?.parent_id && `${doc?.doc_detail?.doc_detail?.name} (${doc?.doc_detail?.name})`) || doc?.doc_detail?.name;
                    result?.leadDocDropDown?.push({ value: doc?.doc_detail?.id, label });
                    result?.alreadyPushed?.push(doc?.doc_detail?.id);
                }
                return result;
            },
            { leadDocDropDown: [], alreadyPushed: [] },
        );
        setLeadDocsName([...leadDocDropDown]);
    }, [leadDocs]);

    useEffect(() => {
        setDocIndex(0);
        const docs = leadDocs.filter((doc) => +doc.doc_id === +selectedDocId);
        setSelectedDocs([...docs]);
    }, [selectedDocId]);

    const setDocIndexValue = (index) => {
        const newIndex = (index + 1) % selectedDocs.length;
        setDocIndex(newIndex);
    };

    const handleValidateSubmit = () => {
        let customer_lead_detail_input = {
            lead_id: validateCustomerInput?.lead_id,
            mobile: +validateCustomerInput?.mobile?.split(' ')[1],
        },
            variables = {
                api_called_by: 'web',
                customer_lead_detail_input,
            };
        executeGraphQLMutation(GET_CUSTOMER_LEAD_DETAIL, variables, client)
            .then((resp) => {
                if (resp?.data?.get_customer_lead_detail) {
                    const lead = resp?.data?.get_customer_lead_detail;
                    const obj = {};
                    if (lead?.lead_status?.name) {
                        obj['lead_status'] = lead?.lead_status?.name || '';
                    }
                    if (lead?.customer?.name) {
                        obj['customer_name'] = lead?.customer?.name || '';
                    }
                    obj['dealer_name'] = 'dealer_name';

                    if (lead?.service_type?.service_type) {
                        obj['service_type'] = lead?.service_type?.service_type || '';
                    }
                    if (lead?.lead_docs) {
                        setLeadDocs(lead?.lead_docs);
                        obj['lead_docs'] = `<a>View Docs</a>`;
                    }
                    if (lead?.lead_detail?.agent_fee) {
                        obj['agent_fee'] = lead?.lead_detail?.agent_fee || 0;
                    }

                    if (lead?.lead_vehicle_detail) {
                        const {
                            make_id,
                            make_name,
                            model_id,
                            model_name,
                            other_make_name,
                            other_model_name,
                            other_variant_name,
                            variant_id,
                            variant_name,
                        } = lead?.lead_vehicle_detail;
                        obj['mmv_name'] = `${(make_id && make_name) || other_make_name} ${(model_id && model_name) || other_model_name
                            } ${(variant_id && variant_name) || other_variant_name}`;
                    }
                    setTitleTransferDetails(obj);
                    setLeadDetails(lead);
                    setIsCustomerValidated(true);
                    toast.success('Customer Validated!');
                    getLeadHistory(lead?.id);
                    getPaymentStatusDetails(lead?.id);
                } else {
                    toast.error('Something went wrong');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleValidateChange = (data, field_name) => {
        const newValues = {};
        if (data && field_name) {
            newValues[field_name] = data.value;
        }
        if (data?.target?.id) {
            newValues[data?.target?.id] = data?.target?.value;
        }
        setValidateCustomerInput((prev) => ({
            ...prev,
            ...newValues,
        }));
    };

    const FORM_FIELDS = [
        {
            id: 'mobile',
            name: 'mobile',
            readOnly: false,
            type: 'pattern-format',
            label: 'Customer mobile no.',
            required: true,
            validations: 'isValidPhoneNumber',
            validationError: '',
            ApplyNumberformat: true,
            format: '+63 ############',
            showAsterisk: true,
        },
    ];

    const showModalEditServicesPrice = () => {
        setEditServicesPrice(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalEditServicesPrice = () => {
        setEditServicesPrice(false);
        document.body.classList.remove('overflow-hidden');
    };

    const LabelValueDisplay = ({ label, value, name }) => {
        return (
            <div className="label-value-container">
                <span className="label">{label}</span>
                {(['lead_status'].includes(name) && <>
                    <div className="status-grid-left">
                        <span className="value">{value}</span>
                        <label className='ancor-label' onClick={showModalViewTimeLine}>History</label>
                    </div>
                </>) ||
                    <span className="value">{value}</span>
                }
            </div>
        );
    };

    const ViewDocsDisplay = ({ label, value, name }) => {
        return (
            <div className="label-value-container">
                <span className="label">{label}</span>

                {(['lead_docs'].includes(name) && <>
                    <div className="status-grid-left">
                        <span className="value">{`${leadDocs?.length || 0} Updated`}</span>
                        <label className="value ancor-label" onClick={showModalEditServicesPrice}>
                            View Docs
                        </label>
                    </div>
                </>) ||
                    <label className="value ancor-label" onClick={showModalEditServicesPrice}>
                        View Docs
                    </label>
                }

            </div>
        );
    };

    return (
        <div className="title-transfer-login-outer">
            <div className="form-container t-center">
                <div className='acceptance_page_logo m-sm-b'>
                    <img src={logo_image} alt='Logo'></img>
                </div>
                <h2>Title Transfer Info</h2>
                {!isCustomerValidated ? (
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleValidateSubmit}
                    >
                        <div className="row">
                            {FORM_FIELDS.map((field) => (
                                <fieldset
                                    class={`form-filed p-lg-t ${(field?.is_full_row && 'col-md-12') || 'col-md-12'}`}
                                >
                                    <FormsyInputField
                                        id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={validateCustomerInput && validateCustomerInput[field.name]}
                                        placeholder=" "
                                        label={field.label}
                                        onChange={(data) => handleValidateChange(data, field.name)}
                                        format={(field.ApplyNumberformat && field.format) || ''}
                                        validations={field.validations}
                                        validationError={validateCustomerInput[field.name] ? field.validationError : ''}
                                        required={field.required}
                                        showAsterisk={field.showAsterisk}
                                    />
                                </fieldset>
                            ))}
                        </div>
                        <div className="btn-save-remarks p-lg-t">
                            {!allowSubmit ? (
                                <span className="span-disabled">Validate</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Validate
                                </button>
                            )}
                        </div>
                    </Formsy>
                ) : showSuccessPage !== "" ? (<>
                    <div className={showSuccessPage === AGENT_ACTION.rejected && "Rejected" ? "status-inactive" : "status-active"}>
                        <span>{(showSuccessPage === AGENT_ACTION.rejected && "Rejected") || (showSuccessPage === AGENT_ACTION.accepted && "Accepted")}</span>
                    </div>
                </>) : (
                    <>
                        {STATUS_TRACKING.map((field) => (
                            <div className="app-container p-md-t">
                                {(field?.id === 'lead_docs' && (
                                    <ViewDocsDisplay
                                        label={field?.label}
                                        value={titleTransferDetails[field?.value] || []}
                                        name={field?.name}
                                    />
                                )) || (
                                        <LabelValueDisplay
                                            label={field?.label}
                                            value={titleTransferDetails[field?.value] || ''}
                                            name={field?.name}
                                        />
                                    )}
                            </div>
                        ))}
                        {

                            (([STATUS_CODES.ORCR_GENERATED].includes(leadDetails?.status_id) && [LEAD_SUB_STATUS.ORCR_SENT_TO_DEALER].includes(leadDetails?.sub_status_id)) && (
                                (leadDetails?.lead_rating && <PreviewRating lead_rating={leadDetails?.lead_rating} page={REVIEW_PAGES.STATUS_TRACKING} />) ||
                                <ReviewRating leadDetails={leadDetails} page={REVIEW_PAGES.STATUS_TRACKING} />
                            )) || null
                        }
                    </>
                )}
            </div>
            <div className="Add-agnet-popup doc-view-gallery-popup">
                <Modal show={editServicesPrice} handleClose={hideModalEditServicesPrice}>
                    <div className='modal-header p-lg-b'>
                        <h2>Doc list</h2>
                    </div>
                    <div className='modal-body'>
                        <div className="tag-image-bx row" key={`${leadDocs?.length}`}>
                            <div className='single-select col-md-12 p-lg-b'>
                                <div className="material">
                                    <Select
                                        options={leadDocsName}
                                        placeholder={'Select Doc'}
                                        name={'selected_doc'}
                                        optionLabel="label"
                                        optionValue="value"
                                        value={leadDocsName.filter((data) => data.value === selectedDocId)}
                                        onChange={(e) => {
                                            setSelectedDocId(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="carousel image-varification-slider">
                            <div className="carousel-slideimg pfd-img-height">
                                {['pdf', 'PDF'].includes(getDocExtension(selectedDocs[docIndex]?.doc_path)) ? (
                                    <a
                                        className="pdf-img"
                                        href={selectedDocs[docIndex]?.doc_path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                                    </a>
                                ) : (
                                    <>
                                        <ReactPanZoom image={selectedDocs[docIndex]?.doc_path} />
                                        {selectedDocs.length > 1 && (
                                            <button onClick={() => setDocIndexValue(docIndex)}>Next</button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

            <div className="view-timeline-popup image-timeline-popup customer-tracking-history">
                {ViewTimeLine ? (
                    <div className="view-timeline-popup image-timeline-popup">
                        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                            <div className="modal-header">
                                <h2>Customer Timeline</h2>
                            </div>
                            <LeadStatusTrackHistory historyList={historyList} paymentStatusDetails={paymentStatusDetails} isLoading={false} t={t} />
                        </Modal>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
export default StatusTracking;
