import { useApolloClient } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../config/constants';
import crypto from '../../config/crypto';
import { STATUS_PAYMENT_DATA } from '../../services/leads.gql';
import Modal from '../elements/Modal';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import AddStatusPaymentDetails from './addPaymentStatus';
import DocumentGallery from './DocumentGallery';
import PaymentDetails from './PaymentDetails';
import StatusDetails from './StatusDetails';

const StatusPaymentDetails = () => {
    const [paymentStatusDetails, setPaymentStatusDetails] = useState([]);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const current_url_main = window.location.href;
    let current_url = current_url_main.split('/');
    let param = current_url[current_url.length - 2];

    const { lead, remarksHistory, leadHistory } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadHistory: lead?.leadDetail?.lead_status_history,
    }));

    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'status-details')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'status-details').is_super_admin
        ? false
        : !(lead.leadDetail?.lead_assigned_to && lead.leadDetail?.lead_assigned_to === UserInfo?.id);

    useEffect(() => {
        let form_type = param === 'status-details' ? 1 : 2;
        let leadId = crypto.decode(lead_id);
        executeGraphQLQuery(STATUS_PAYMENT_DATA(+leadId, form_type), client)
            .then((resp) => {
                if (resp?.data?.get_status_payment_details)
                    setPaymentStatusDetails(resp?.data?.get_status_payment_details);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [param]);

    const isAddStatusDisabled = useMemo(() => {
        let is_payment_details_freeze = lead?.leadDetail?.is_payment_details_freeze || '';
        return ((param !== 'status-details' ? is_payment_details_freeze : true) && leadHistory?.filter((v) => [6].includes(v.status_id))?.length) ? true : false;
    }, [leadHistory, param, lead?.leadDetail]);

    const isSubmitDisabled = useMemo(() => {
        return !lead?.leadDetail?.is_stages_complete;
    }, [lead?.leadDetail?.is_stages_complete]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(false);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const showRemarkPopup = () => {
        setViewTimeLine(false);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideRemarkPopup = () => {
        setShowremarkform(false);
        document.body.classList.remove('overflow-hidden');
    };

    const navigateToNewOrcr = () => {
        navigate(`/lead-detail/new-orcr/${lead_id}`);
    };


    const sectionInfo =
        param === 'status-details'
            ? { section: 'Status details', sub_section: 'Status details' }
            : { section: 'Payment details', sub_section: 'Payment details' };

    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading d-flex">
                        <h2>{param === 'status-details' ? 'Status Details' : 'Payment Details'}</h2>

                        <button
                            type="button"
                            className="btn-line add-status-btn"
                            onClick={() => showModalViewTimeLine()}
                            disabled={isAddStatusDisabled || getContext.markAsFreezed || accessCondition || isLeadAssigned}
                        >
                            <i className="ic-add m-xs-r"></i>
                            {param === 'status-details' ? 'Add Status' : 'Add Payment Proof'}
                        </button>
                    </div>
                    <div className="status-payment-detail-outer">
                        {param === 'status-details' ? (
                            <StatusDetails paymentStatusDetails={paymentStatusDetails} />
                        )
                            : (
                                <PaymentDetails paymentStatusDetails={paymentStatusDetails} />
                            )

                        }
                    </div>

                    <div className="btn-save-remarks p-lg-t p-l-30">
                        {isSubmitDisabled || getContext.markAsFreezed || accessCondition || isLeadAssigned ? (
                            <span className="span-disabled">Next</span>
                        ) : (
                            <button aria-label="save naxt" type="submit" className="btn-primary" onClick={navigateToNewOrcr}>
                                Next
                            </button>
                        )}
                        <button
                            aria-label="remarks history"
                            type="button"
                            className="btn-line"
                            onClick={showRemarkPopup}
                        >
                            Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="Add-Financer-popup add-payment-record-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <AddStatusPaymentDetails lead={lead} param={param} user_id={user_id} paymentStatusDetails={paymentStatusDetails} />
                </Modal>
            </div>
            <div className="view-timeline-popup">
                <Modal show={showremarkform} handleClose={hideRemarkPopup}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default StatusPaymentDetails;
