import { PAYMENT_TYPE } from "../../../config/constants";

export const AddStatusDetail = {
    "ADD_STATUS": [
        {
            "id": "is_in_progress_completed",
            "type": "radio",
            "label": "In Progress Stage Completed",
            "is_full_row": true,
            "options": [
                {
                    "name": "is_in_progress_completed",
                    "value": "Yes",
                    "label": "Yes"
                },
                {
                    "name": "is_in_progress_completed",
                    "value": "No",
                    "label": "No"
                }
            ],
            "dependentOn": "is_in_progress_present",
            "dependentValue": [
                true
            ]
        },
        {
            "id": "in_progress_stage_id",
            "name": "in_progress_stage_id",
            "readOnly": false,
            "type": "dropdown",
            "label": "Process Selection",
            "required": true,
            "validations": null,
            "validationError": "",
            "ApplyNumberformat": false,
            "optionsKey": "in_progress_stages_option",
            "showAsterisk": true,
            "dependentOn": "is_in_progress_completed",
            "dependentValue": [
                "Yes"
            ]
        },
        {
            "id": "in_progress_completion_date",
            "name": "in_progress_completion_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Actual Completion Date",
            "required": true,
            "validations": null,
            "validationError": "Please select Actual Completion Date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dependentOn": "is_in_progress_completed",
            "dependentValue": [
                "Yes"
            ]
        },
        {
            "id": "title",
            "name": "title",
            "type": "text",
            "label": "Title",
            "required": true,
            "validations": null,
            "validationError": "",
            "ApplyNumberformat": false,
            "readOnly": false,
            "showAsterisk": true
        },
        {
            "id": "status_stage_id",
            "name": "status_stage_id",
            "readOnly": false,
            "type": "dropdown",
            "label": "Stage Selection",
            "validations": null,
            "validationError": "",
            "ApplyNumberformat": false,
            "optionsKey": "status_update_stages",
            "required": true,
            "showAsterisk": true
        },
        {
            "id": "start_label",
            "name": "start_label",
            "type": "labels",
            "label": "In Progress Stage",
            "is_full_row": false,
            "dependentOn": "status_stage_id",
            "dependentValue": [
                1, 2, 3, 4, 5, 6
            ]
        },
        {
            "id": "complete_label",
            "name": "complete_label",
            "type": "labels",
            "label": "Completed Stage",
            "is_full_row": false,
            "dependentOn": "status_stage_id",
            "dependentValue": [
                1, 2, 3, 4, 5, 6
            ]
        },
        {
            "id": "start_date",
            "name": "start_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Start Date",
            "validations": null,
            "validationError": "Please select start date",
            "ApplyNumberformat": false,
            "required": false,
            "showAsterisk": false,
            "dependentOn": "status_stage_id",
            "dependentValue": [
                1, 2, 3, 4, 5, 6
            ]
        },
        {
            "id": "expected_completion_date",
            "name": "expected_completion_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Expected Completion Date",
            "validations": null,
            "validationError": "Please select Expected Completion Date",
            "ApplyNumberformat": false,
            "required": false,
            "showAsterisk": false,
            "dependentOn": "status_stage_id",
            "dependentValue": [
                1, 2, 3, 4, 5, 6
            ]
        },
        {
            "id": "completion_date",
            "name": "completion_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Actual Completion Date",
            "validations": null,
            "validationError": "Please select Actual Completion Date",
            "ApplyNumberformat": false,
            "required": false,
            "showAsterisk": false,
            "dependentOn": "status_stage_id",
            "dependentValue": [
                1, 2, 3, 4, 5, 6
            ]
        },
        {
            "id": "followup_date",
            "name": "followup_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Next follow up date",
            "validations": null,
            "validationError": "Please select Actual Completion Date",
            "ApplyNumberformat": false,
            "required": false,
            "showAsterisk": false
        },
        {
            "id": "remark",
            "name": "remark",
            "readOnly": false,
            "type": "text",
            "label": "Remark",
            "validations": null,
            "validationError": "",
            "ApplyNumberformat": false,
            "required": false,
            "showAsterisk": false,
            "is_full_row": true
        }
    ],
    "ADD_PAYMENT_RADIO_OPTIONS": [
        { "id": "payment_type", "type": "radio", "label": "Select Payment Type", "options": [{ "name": "payment_type", "value": PAYMENT_TYPE.paid, "label": "Paid" }, { "name": "payment_type", "value": PAYMENT_TYPE.received, "label": "Received" }] },
    ],
    "ADD_PAYMENT_DATE_TYPE": [
        {
            "id": "payment_request_date",
            "name": "payment_request_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Requested Date",
            "placeholder": "Requested Date",
            "required": true,
            "validations": null,
            "validationError": "Please select date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dateFormat": "dd MMM, yyyy",
            "dependentOn": "payment_type",
            "dependentValue": [PAYMENT_TYPE.paid
            ]
        },
        {
            "id": "payment_paid_date",
            "name": "payment_paid_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Paid Date",
            "placeholder": "Paid Date",
            "required": true,
            "validations": null,
            "validationError": "Please select date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dateFormat": "dd MMM, yyyy",
            "dependentOn": "payment_type",
            "dependentValue": [PAYMENT_TYPE.paid
            ]
        },
        {
            "id": "payment_received_date",
            "name": "payment_received_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Received Date",
            "placeholder": "Received Date",
            "required": true,
            "validations": null,
            "validationError": "Please select date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dateFormat": "dd MMM, yyyy",
            "dependentOn": "payment_type",
            "dependentValue": [PAYMENT_TYPE.received
            ]
        }
    ],
    "REMARK": [
        {
            "id": "remark",
            "name": "remark",
            "readOnly": false,
            "type": "text",
            "label": "Remark",
            "required": false,
            "validations": null,
            "validationError": "",
            "ApplyNumberformat": false,
            "showAsterisk": false,
            "is_full_row": true
        }
    ]
};