import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import { DATE_FORMAT, FILTER_KEY_CHIPS_VALUE, SEARCH_TYPE, STANDALONE_ORCR_STATUS } from '../../config/constants';
import MultiSelect from '../elements/MultiSelect';

const LeadListFilter = ({ handleSearch, rolesWithUsers, paramsObject, getLeadsInput: filterData, setGetLeadsInput: setFilterData, filtersArr, setFiltersArr, refreshFilterChips, statuses }) => {
    const { masterdata } = useSelector(({ masterdata }) => ({
        masterdata,
    }));
    const [dealerList] = useState(masterdata?.dealerList);
    const [values, setValues] = useState(['', '']);

    useEffect(() => {
        createFilterChips();
    }, [refreshFilterChips]);
    const handleChange = async (name, value, isMulti, opt, optl) => {
        let hcfilterData = {};
        let filterDetails = {};
        if (['service_type_id', 'status_id', 'agent_id'].includes(name)) {
            hcfilterData[name] = value.map((obj) => obj[opt]);
        } else if (['search_text'].includes(name)) {
            hcfilterData[name] = value.target.value;
        } else if (['reportee_role_id'].includes(name)) {
            hcfilterData["reportee"] = [{ role_id: value.value }];
        } else if (['reportee_user_ids'].includes(name)) {
            const user_ids = value.map((obj) => obj[opt]);
            filterData['reportee'][0].user_ids = user_ids;
            hcfilterData["reportee"] = filterData['reportee'];
        } else if (['source_type_id'].includes(name)) {
            hcfilterData[name] = value.id;
        } else {
            hcfilterData[name] = value.value;
        }
        setFilterData((prev) => ({
            ...prev,
            ...hcfilterData
        }));
        setFiltersArr((prev) => ({
            ...prev,
            ...filterDetails
        }));
    };

    const createFilterChips = (key) => {
        let filterDetails = {};
        for (const key in filterData) {
            if (["service_type_id",
                "status_id",
                "agent_id",,
                "search_text",
                "source_type_id",
                "search_type",
                "date_type",
                "from_date",
                "to_date"].includes(key)) {
                Object.assign(filterDetails, chipsMasterObj[key](key));
            }
        }
        if (filterData?.["reportee"]?.length) {
            filterDetails = {
                ...filterDetails, ...chipsMasterObj["reportee"]("reportee")
            };
        }
        setFiltersArr((prev) => ({
            ...filterDetails
        }));
    };

    const chipsMasterObj = {

        "status_id": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, statuses, filterData[key], "id", "value") }) || {},
        "search_text": (key) => (filterData?.[key] && { [key]: `${FILTER_KEY_CHIPS_VALUE[key]} - ${filterData?.[key]}` }) || {},
        "search_type": (key) => (filterData?.[key] && { [key]: prepareChips(key, SEARCH_TYPE, [filterData[key]], "value", "label") }) || {},
        "from_date": (key) => (filterData?.[key] && { [key]: `${FILTER_KEY_CHIPS_VALUE["from_date"]} - ${moment(filterData?.[key]).format(DATE_FORMAT)}` }) || {},
        "to_date": (key) => (filterData?.[key] && { [key]: `${FILTER_KEY_CHIPS_VALUE["to_date"]} - ${moment(filterData?.[key]).format(DATE_FORMAT)}` }) || {},
    };

    const prepareChips = (masterKey, sourceData = [], idsToSearch = [], key, label) => {
        const value = sourceData?.reduce((result, val) => {
            if (idsToSearch.includes(val?.[key])) {
                if (result) result += `, ${val?.[label]}`;
                else result += val?.[label];
            }
            return result;
        }, '');
        return `${FILTER_KEY_CHIPS_VALUE[masterKey]} - ${value}`;
    };
    const searchLead = (e) => {
        handleSearch(filterData);
    };

    const resetSearchLead = (e) => {
        // window.location.href = "/lead-list";
        let filterDataTemp = filterData;
        let status_ids = [];
        if (filterDataTemp?.action_type === 'new_cases') status_ids = [1];
        document.getElementById('more-filter-dropdown').classList.remove('show');
        setFilterData({ action_type: filterDataTemp?.action_type, status_id: status_ids });
        setFiltersArr({});
        setValues(['', '']);
        handleSearch({ action_type: filterDataTemp?.action_type, status_id: status_ids });
    };

    const selectDate = (dtrange) => {
        const hcfilterData = {};
        hcfilterData["from_date"] = moment(new Date(dtrange?.[0])).format(DATE_FORMAT + ' 00:00:00');
        if (dtrange?.[1]) {
            hcfilterData["to_date"] = moment(new Date(dtrange?.[1])).format(DATE_FORMAT + ' 23:59:59');
        }
        setFilterData((prev) => ({
            ...prev,
            ...hcfilterData
        }));
    };

    const removeFilter = (key) => {
        let filterDataObj = filterData;
        let filtersArrObj = filtersArr;
        if (["reportee_user_ids", "reportee_role_id"].includes(key)) {
            delete filterDataObj["reportee"];
            delete filtersArrObj["reportee_user_ids"];
            delete filtersArrObj["reportee_role_id"];
        }
        if (["date_type"].includes(key)) {
            delete filterDataObj["from_date"];
            delete filterDataObj["to_date"];
            delete filtersArrObj["from_date"];
            delete filtersArrObj["to_date"];
            setValues(['', '']);
        }
        if (["from_date"].includes(key)) {
            values[0] = "";
            setValues([...values]);
        }
        if (["to_date"].includes(key)) {
            values[1] = "";
            setValues([...values]);
        }
        delete filterDataObj[key];
        delete filtersArrObj[key];
        setFilterData((prev) => ({
            ...filterDataObj
        }));
        setFiltersArr((prev) => ({
            ...filtersArrObj
        }));
        searchLead(filterDataObj);
    };

    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <div className='filter-filed-form'>
                    <fieldset className="search-bx">
                        <div className="material">
                            <input
                                id=""
                                type="text"
                                aria-label="search text"
                                name="search_text"
                                value={filterData?.search_text || ''}
                                onChange={(e) => handleChange('search_text', e)}
                                className="form-input"
                                title={'SEARCH_TEXT'}
                                placeholder="Search By Application Id"
                            />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                options={(filterData?.action_type === "new_cases" ? statuses.filter((st) => +st.id === STANDALONE_ORCR_STATUS.RECIEVED) : statuses) || []}
                                placeholder={'Select Status'}
                                name={'status_id'}
                                optionLabel="value"
                                optionValue="id"
                                className='react-select'
                                classNamePrefix='react-select'
                                values={filterData?.status_id || []}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>

                    <fieldset className='form-filed col-md-12' >
                        <div className="material">
                            <div className="datepicker">
                                <DatePicker
                                    value={values}
                                    range
                                    maxDate={new Date()}
                                    onChange={selectDate}
                                    format={'YYYY-MM-DD'}
                                    className='w-100'
                                    placeholder='Date Range'
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className="more-filter">
                    <div className="filter-option">
                        <Dropdown id="more-filter-dropdown">
                            <div className="text-btn d-flex">
                                <div className="btn-submit-reset top-btn-none">
                                    <button onClick={searchLead} className="btn-primary">
                                        Search
                                    </button>
                                    <button onClick={resetSearchLead} className="btn-reset">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                </div>


            </div>
            <div className="selected-chips">
                <ul>
                    {Object.keys(filtersArr)?.map((key) => (
                        <li key={key}>
                            {filtersArr?.[key]}
                            <span>
                                <i className="ic-clearclose" onClick={() => removeFilter(key)}></i>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default LeadListFilter;
