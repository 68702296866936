export const FuelType = [
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Petrol', label: 'Petrol' },
    { value: 'Electric', label: 'Electric' },
    { value: 'Gas', label: 'Gas' },
    { value: 'Hybrid', label: 'Hybrid' },
    { value: 'Diesel/sport', label: 'Diesel/sport' },
    { value: 'Gas/Electric', label: 'Gas/Electric' },
    { value: 'Gas/Hybrid', label: 'Gas/Hybrid' },
    { value: 'Gasoline', label: 'Gasoline' },
    { value: 'Flex', label: 'Flex' },
];
export const Transmission = [
    { value: 'Automatic', label: 'Automatic' },
    { value: 'Manual', label: 'Manual' },
    { value: 'CVT', label: 'CVT' },
    { value: 'Dual Clutch', label: 'Dual Clutch' },
];
export const Color = [
    { value: 'Red', label: 'Red' },
    { value: 'White', label: 'White' },

];

export const SeatingCapacity = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
];

export const ReasonNotVerify = [
    { label: 'Name Mismatch', value: 'Name Mismatch' }, 
    { label: 'Plate Number Mismatch', value: 'Plate Number Mismatch' }, 
    { label: 'Previous Violation', value: 'Previous Violation' }, 
    { label: 'Other', value: 'Other' }
];