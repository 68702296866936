const DocumentStandaloneGallery = ({ groupDocs, documents, setDocuments, uploadDocs, setUploadDocs, handleDocUpload, handleImageClick }) => {

    const handleRemove = (docId, index) => {
        // Create copies of documents and uploadDocs arrays for immutability
        const updatedDocuments = { ...documents };
        const updatedUploadDocs = { ...uploadDocs };

        // Check if the document ID exists in both documents and uploadDocs, and has the specified index
        if (updatedDocuments[docId] && updatedUploadDocs[docId] &&
            updatedDocuments[docId][index] && updatedUploadDocs[docId][index]) {
            
            // Remove the item at the specified index
            updatedDocuments[docId]?.splice(index, 1);
            updatedUploadDocs[docId]?.splice(index, 1);

            // Update the state with the modified arrays
            setDocuments(updatedDocuments);
            setUploadDocs(updatedUploadDocs);
        }
    };

    return (
      <div className="ocr-upload-left-sec ocr-bg-inner">
        <h2>Upload Documents</h2>
        {groupDocs.map((doc) => doc.is_required && (
          <div className="ocr-upload-content" key={doc.id}>
            <div className="doc-type-name">{doc.name} *</div>
            <div className="row grid-item-list">
              {documents[doc.id]?.map((displayDoc, idx) => (
                <div className="col-md-4" key={idx}>
                  <div className="img-bx-select-opt image-grid-list overflow-h">
                    <div className="image-bx">
                      <img src={displayDoc} alt="Document Preview" />
                    </div>
                    <div className="close-icn" onClick={() => handleRemove(doc.id, idx)}>
                      <i className="ic-clearclose"></i>
                    </div>
                  </div>
                </div>
              ))}
              {documents[doc.id].length < doc.max_upload && (
                <div className="col-md-4">
                  <div className="img-bx-select-opt image-grid-list overflow-h">
                    <div className="image-bx" onClick={() => handleImageClick(doc.id)} style={{cursor: "pointer"}}>
                      <i className="ic-add" style={{ fontSize: "2rem" }}></i>
                      <input
                        type="file"
                        accept="image/*, .pdf, .doc, .docx"
                        id={`file-input-${doc.id}`}
                        name={doc.id}
                        onChange={(e) => handleDocUpload(e, doc.id)}
                        style={{ display: "none" }}
                      />
                    </div>
                    <p style={{ textAlign: 'center' }}>Add Photo</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) || <></>)}

        {groupDocs.map((doc) => !doc.is_required && documents[doc.id]?.length !== 0 && (
          <div className="ocr-upload-content" key={doc.id}>
            <div className="doc-type-name">{doc.name}</div>
            <div className="row grid-item-list">
              {documents[doc.id]?.map((displayDoc, idx) => (
                <div className="col-md-4" key={idx}>
                  <div className="img-bx-select-opt image-grid-list overflow-h">
                    <div className="image-bx">
                      <img src={displayDoc} alt="Document Preview" />
                    </div>
                    <div className="close-icn" onClick={() => handleRemove(doc.id, idx)}>
                      <i className="ic-clearclose"></i>
                    </div>
                  </div>
                </div>
              ))}
              {documents[doc.id].length < doc.max_upload && (
                <div className="col-md-4">
                  <div className="img-bx-select-opt image-grid-list overflow-h">
                    <div className="image-bx" onClick={() => handleImageClick(doc.id)} style={{cursor: "pointer"}}>
                      <i className="ic-add" style={{ fontSize: "2rem" }}></i>
                      <input
                        type="file"
                        accept="image/*, .pdf, .doc, .docx"
                        id={`file-input-${doc.id}`}
                        name={doc.id}
                        onChange={(e) => handleDocUpload(e, doc.id)}
                        style={{ display: "none" }}
                      />
                    </div>
                    <p style={{ textAlign: 'center' }}>Add Photo</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) || <></>)}
      </div>
    );
};

export default DocumentStandaloneGallery;
