import React, { useState } from 'react';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import GeneralService from '../../services/generalService';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import ImportFile from '../../common/ImportFIle';
import Loader from '../elements/Loader';
import { UPDATE_AGENT_FEE_AND_DOC_STANDALONE_ORCR } from '../../services/standalone-orcr.gql';

const UploadAgentFee = ({ lead, user_id, getLeadList, toggleModalBoxremark, getLeadsInput, paginationInput }) => {
    const [agentFeeData, setAgentFeeData] = useState([]);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageName, setImageName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const client = useApolloClient();

    const handleToUploadLogo = async (event) => {
        let file = event.target.files[0];
        var doc_path = '';
        if (file) {
            const formData = new FormData();
            formData.append('upload_type', 'finance_docs');
            formData.append('lead_id', lead?.id);
            formData.append('images', file);

            try {
                const publicUrl = await GeneralService.uploadFilesToS3(formData, {
                    'Content-Type': 'multipart/form-data',
                });
                doc_path =
                    publicUrl &&
                    publicUrl.data &&
                    publicUrl.data.data &&
                    publicUrl.data.data.length &&
                    (publicUrl.data.data[0].file_url || '');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        setImagePreview(doc_path);
        setImageName(file?.name);
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setAgentFeeData((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };

    const updateAgentFee = async (e) => {
        e.preventDefault();
        if (!agentFeeData?.agent_fee || agentFeeData?.agent_fee === 0) {
            toast.error('Please fill agent fee');
            return;
        }
        if (!imagePreview) {
            toast.error('Please upload agent doc');
            return;
        }
        setIsLoading(true);
        let variables = {
            api_called_by: 'web',
            user_id: user_id || 0,
            updateagentinput: {
                lead_id: lead?.id,
                agent_fee: agentFeeData?.agent_fee ? Number(agentFeeData?.agent_fee) : 0,
                docs: [imagePreview] || null
            }
        };
        await executeGraphQLMutation(UPDATE_AGENT_FEE_AND_DOC_STANDALONE_ORCR(), variables, client)
            .then((res) => {
                if (res?.data?.standalone_update_agent_fee_and_docs) {
                    toast.success(res?.data?.standalone_update_agent_fee_and_docs?.message);
                    getLeadList({
                        getLeadsInput,
                        paginationInput
                    });
                    toggleModalBoxremark({});
                    setIsLoading(false);
                } else {
                    toast.error('Error');
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                toast.error(err.toString().replace('ApolloError:', ''));
                setIsLoading(false);
            });
    };

    return (
        <div>
            {isLoading ? <Loader /> : null}
            {/* <div className="modal-header">
                <h2>Upload Agent Fee</h2>
            </div> */}

            <div className="modal-body">
                <form>
                    <div className="">
                        <>
                            <Formsy
                                className="lead-form-filed"
                                autoComplete="off"
                                onValid={() => setAllowSubmit(true)}
                                onInvalid={() => setAllowSubmit(false)}
                                onValidSubmit={updateAgentFee}
                                aria-label="lead form field basic"
                            >
                                <div className="row">
                                    <fieldset class="form-filed col-md-12 m-lg-b add-payment-datpicker">
                                        <FormsyInputField
                                            id="agent_fee"
                                            name="agent_fee"
                                            type="number-format"
                                            value={agentFeeData?.agent_fee || ''}
                                            placeholder=" "
                                            label="Payment Amount"
                                            onChange={(data) => handlePatternFormatChange(data, 'agent_fee')}
                                            validations={null}
                                            validationError=""
                                            readOnly={lead?.lead_details?.agent_fee}
                                            required={true}
                                            showAsterisk={true}
                                        />
                                    </fieldset>

                                    <fieldset className="form-filed col-md-12 fin-logo-row">
                                        <div className="image-uploader-outer">
                                            <ImportFile name={imageName} onChooseFile={handleToUploadLogo} field_text={'Upload Proof by dropping file here'} accept="image/*, .pdf, .doc, .docx"/>
                                        </div>
                                    </fieldset>
                                </div>
                                <br></br>
                                <div className="btn-save-remarks">
                                    <button
                                        aria-label="save naxt"
                                        onClick={updateAgentFee}
                                        className="btn-primary"
                                        disabled={!allowSubmit || !imageName || lead?.lead_detail?.agent_fee}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Formsy>
                        </>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UploadAgentFee;
